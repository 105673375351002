import { NormalSelectOptions } from './../../../../resource/model/common.d';
import { feedbackService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { FeedbackList } from '@/resource/model';

import { messageError, translation } from '@/utils';

import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
// import SelectCurrency from './select-currency/select-currency.vue';

@Component({
  name: 'EditFeedbackSuggestion',
  components: {}
})
export default class EditFeedbackSuggestion extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: FeedbackList | null;
  public dialogVisible: boolean = false;

  public form: { id: number; status: number; handleOpinion: string } = {
    id: 0,
    status: 1,
    handleOpinion: ''
  };

  public resourceFormRules = {
    status: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputCode')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('unitsMeasure.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
      // {
      //   validator: (rule: any, value: string, callback: Function): void => {
      //     feedbackService
      //       .checkName(value, this.position?.id)
      //       .then((isRepeat: boolean) => {
      //         if (isRepeat) {
      //           callback(translation('requestError.regionNameRepeat'));
      //           return;
      //         }
      //         callback();
      //       })
      //       .catch(error => {
      //         callback(error);
      //       });
      //   },
      //   trigger: 'blur'
      // }
    ]
  };

  public feedbackStatusList: NormalSelectOptions = [];

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    this.getStatusList();
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'orderChange.edit';
      this.$nextTick(() => {
        Object.assign(this.form, this.position);
      });
      return;
    }
    this.operationType = 'add';
    this.title = 'orderChange.edit';
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.form as Form).resetFields();
  }

  public getStatusList(): void {
    this.feedbackStatusList = feedbackService.getFeedbackStatus();
  }

  public onSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        await feedbackService.put({ ...this.form } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
