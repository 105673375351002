import { minioService } from '@/api';
import feedbackService from '@/api/feedback-suggestion/suggestion';
import { DialogMixin } from '@/mixins/dialog';
import { FeedbackSuggestionStatus } from '@/resource/enum';
import { FeedbackDetail } from '@/resource/model';
import { downloadFileByPath, messageError, translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class FeedbackDetails extends mixins(DialogMixin) {
  @Prop({ type: Number, required: true })
  public feedbackId!: number;

  public feedbackInfo: FeedbackDetail = {
    id: 0,
    code: '',
    createUserName: '',
    content: '',
    createTime: '',
    type: '',
    source: '',
    status: 0,
    fileList: [],
    handleOpinion: ''
  };

  public get feedbackSource(): string {
    if (this.feedbackInfo!.source) {
      return translation(`feedbackSource.${this.feedbackInfo!.source}`);
    }
    return '--';
  }

  public dialogOpen(): void {
    if (this.feedbackId) {
      minioService.init();
      this.getFeedbackDetails(this.feedbackId);
    }
  }

  public dialogClosed(): void {}

  public async download(urlPath: string, fileName: string): Promise<void> {
    try {
      const path = await minioService.getDownLoadPath(urlPath);
      downloadFileByPath(fileName, path);
    } catch (error) {
      messageError(error);
    }
  }
  public getStatusI18Key(status: FeedbackSuggestionStatus): string {
    return feedbackService.getStatusI18Key(status);
  }
  /**
   * 详情
   * @param id
   */
  private getFeedbackDetails(id: number): void {
    feedbackService
      .getDetails(id)
      .then(res => {
        this.feedbackInfo = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
