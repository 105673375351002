import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { FeedbackList, FeedbackQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { dateFormat, downloadFileByBlob, messageError, translation } from '@/utils';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';

import { feedbackService } from '@/api';
import { DataDictionaryService } from '@/api/data-dictionary';
import { NormalSelectOptions } from '@/resource/model/common';
import FeedbackDetails from './feedback-details/feedback-details.vue';
import EditFeedbackSuggestion from './edit-feedback-suggestion/edit-feedback-suggestion.vue';
import { FeedbackSuggestionStatus } from '@/resource/enum';

@Component({
  name: 'FeedbackSuggestionsList',
  components: {
    EditFeedbackSuggestion,
    FeedbackDetails
  }
})
export default class FeedbackSuggestionsList extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<FeedbackList> = {
    loading: false,
    data: []
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<FeedbackList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'feedback.code',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'type',
      label: 'feedback.type',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as FeedbackList).type.toString();
      }
    },
    {
      prop: 'content',
      label: 'feedback.content',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'feedback.status',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'feedback.createUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'feedback.createTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as FeedbackList).createTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'source',
      label: 'feedback.source',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (row: Object): string => {
        return translation(`feedbackSource.${(row as FeedbackList).source}`);
      }
    }
  ];
  public feedbackTypes: NormalSelectOptions = [];
  public selectedRows: Array<FeedbackList> = [];

  public feedbackId: number | null = null;

  /**
   * 控制详情dialog显隐
   */
  public detailsVisible = false;

  public dialogEditVisible = false;

  public editRow: FeedbackList | null = null;

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      slot: 'start',
      operationType: 'add'
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<FeedbackList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'feedback:edit',
        handleClick: this.edit
      }
    ]
  };

  /**
   * 查询条件配置
   */
  public queryItemsOption: Array<OsQueryItemOption<FeedbackQuery>> = [
    {
      type: 'Input',
      field: 'content',
      label: 'feedback.content',
      option: {
        placeholder: 'feedback.content'
      }
    },
    {
      type: 'Select',
      field: 'type',
      label: 'feedback.type',
      option: {
        placeholder: 'feedback.type'
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'database.approveStatus',
      option: {
        placeholder: 'database.approveStatus'
      },
      optionData: feedbackService.getFeedbackStatus
    },
    {
      type: 'Select',
      field: 'source',
      label: 'feedback.source',
      option: {
        placeholder: 'feedback.source'
      },
      optionData: feedbackService.getFeedbackSource
    },
    {
      type: 'DateRangePicker',
      field: 'create',
      label: 'feedback.create',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'feedback.createUserName',
      option: {
        placeholder: 'feedback.createUserName'
      }
    }
  ];

  public queryForm: Partial<FeedbackQuery> = {};

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: FeedbackSuggestionStatus): string {
    return feedbackService.getStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: FeedbackSuggestionStatus): string {
    return feedbackService.getStatusClass(status);
  }

  public edit(rowData: FeedbackList): void {
    // 修改数据
    this.editRow = rowData;
    this.dialogEditVisible = true;
  }

  public dialogClosed(): void {
    this.editRow = null;
  }

  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }
  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'feedbackTable');
    this.getFeedbackType();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.feedbackTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }
  public queryClick(): void {
    this.loadData();
  }

  /**
   * 跳转至项目详情
   */
  public linkToDetails(feedbackRow: FeedbackList): void {
    this.feedbackId = feedbackRow.id;
    this.detailsVisible = true;
  }

  public handleSelectionChange(selectedData: Array<FeedbackList>): void {
    this.selectedRows = selectedData;
  }

  public batchEditTimeSuccess(): void {
    this.reloadData();
  }

  private async loadData(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const res = await feedbackService.getList(this.queryForm, this.paging);
      this.tableOption.data = res.data || [];
      this.totalData = res.total || 0;
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<FeedbackList>): void {
    const dynamicBtnTypes: Array<string> = ['batchEditTime'];
    this.operationOptions.forEach(x => {
      if (dynamicBtnTypes.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  @Watch('queryForm.create')
  private handleDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  private async exportData(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const idList = this.selectedRows.map(x => x.id!);
      const blob = await feedbackService.export(this.queryForm as FeedbackQuery, idList);
      downloadFileByBlob(`${translation('feedback.exportFileName')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private async getFeedbackType(): Promise<void> {
    try {
      this.feedbackTypes = (await DataDictionaryService.getFeedbackType()).map(x => {
        return {
          label: x.value,
          value: x.value
        };
      });

      const classifyQuery = this.queryItemsOption.find(x => x.field === 'type');
      if (classifyQuery) {
        classifyQuery.optionData = this.feedbackTypes;
      }
    } catch (error) {
      messageError(error);
    }
  }
}
